import React from 'react'
import { css } from '@emotion/core'

const digitCss = css`
  display: inline-block;
  width: 0.55em;
  text-align: center;
`

export default ({ value }) =>
  [...value].map((d, i) => (
    <span key={i} css={digitCss}>
      {d}
    </span>
  ))
