import React from 'react'
import styled from '@emotion/styled'

const SVG = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SVGButton = ({
  Icon,
  size = 40,
  iconSize = 16,
  style,
  align = 'center',
  ...props
}) => (
  <SVG
    {...props}
    style={{ justifyContent: align, height: size, width: size, ...style }}
  >
    <Icon style={{ height: iconSize, width: iconSize }} />
  </SVG>
)

export default SVGButton
