import React from 'react'
import Layout from './src/global/Layout'
import ResizeObserver from 'resize-observer-polyfill'

export const onClientEntry = () => {
  window.ResizeObserver = window.ResizeObserver || ResizeObserver
  if (process.env.NODE_ENV !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, { logOnDifferentValues: true })
  }
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout props={props}>{element}</Layout>
}
