import React from 'react'
import { Global, css } from '@emotion/core'
import { IntlProvider } from 'react-intl'

import Notification from './Notification'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import Footer from './Footer'
import { AudioPlayerProvider } from '../hooks/useAudioPlayer'
import AudioPlayer from './AudioPlayer'
import { PageContext } from '../hooks/usePageContext'
import SEO from './Seo'

import globalStyles from '../styles/globalStyles'

import media from '../styles/mq'

const pageCss = css`
  ${media.greaterThan('large')} {
    width: calc(100% - var(--player-height));
  }
`

const fullPageCss = css`
  ${media.greaterThan('medium')} {
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 600px;
    margin-bottom: var(--space-medium);
    > main {
      flex: 1;
    }
  }
`

const Layout = ({ children, props }) => {
  const { data, path } = props
  if (!data || !data.dato) return <main>{children}</main>

  const { page, notification, ...rest } = data.dato

  const isHomePage = page && page.slug === ''

  const pageContext = { isHomePage, ...props.pageContext, ...rest }

  return (
    <IntlProvider
      locale={props.pageContext.locale}
      messages={props.pageContext.intl}
    >
      <Global styles={globalStyles} />
      <PageContext.Provider value={pageContext}>
        {page && <SEO seoData={page.seo} />}
        <AudioPlayerProvider>
          <AudioPlayer />
          <div css={pageCss}>
            {notification && <Notification content={notification} />}
            <div css={isHomePage && fullPageCss}>
              <HeaderDesktop />
              <HeaderMobile path={path} />
              <main>{children}</main>
            </div>
            <Footer backToTop={!isHomePage} />
          </div>
        </AudioPlayerProvider>
      </PageContext.Provider>
    </IntlProvider>
  )
}

export default Layout
