import React from 'react'
import { css } from '@emotion/core'

const svgCss = css`
  position: relative;
  width: 5rem;
  animation: rotate 20s linear infinite;
  text {
    text-transform: uppercase;
    letter-spacing: 0;
    font-size: 20.8px;
    fill: currentColor;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

function Logo({ id }) {
  return (
    <svg
      css={svgCss}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 200 200"
    >
      <defs>
        <path
          id={`svg-logo-path-${id}`}
          d="M100 37c34.8 0 63 28.2 63 63s-28.2 63-63 63-63-28.2-63-63 28.2-63 63-63z"
          transform="rotate(-88 100 100)"
        ></path>
      </defs>
      <text>
        <textPath xlinkHref={`#svg-logo-path-${id}`}>
          Gabriel Bismut • Gabriel Bismut •{' '}
        </textPath>
      </text>
    </svg>
  )
}

export default Logo
