import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
  useReducer
} from 'react'

const noop = () => {}

const AudioPlayerContext = React.createContext()

export function AudioPlayerProvider({ children }) {
  const player = useRef(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [volume, setVolume] = useState(1)
  const [index, setIndex] = useState(0)
  const loadChangedIndex = useRef(false)
  const forceReload = useRef(false)

  const tracks = useRef([])
  const [shouldReload, reload] = useReducer(x => x + 1, 0)

  const hasEndedNaturally = () =>
    player.current.duration === player.current.currentTime

  const load = useCallback(source => {
    if (hasEndedNaturally()) return // onend will change index and trigger a re-render
    tracks.current = Array.isArray(source) ? source : [source]
    const i = tracks.current.indexOf(player.current.src)
    if (i >= 0) {
      setIndex(_i => {
        if (_i !== i) loadChangedIndex.current = true
        return i
      })
    } else {
      // current playing song doesn't exist in the new track list
      forceReload.current = true
      setIndex(0)
      reload() // force reload in case index was already 0
    }
  }, [])

  useEffect(() => {
    if (loadChangedIndex.current) {
      loadChangedIndex.current = false
      return
    }
    const newTrack = tracks.current[index]
    if (player.current.src === newTrack) return
    const wasPlaying = !player.current.paused || hasEndedNaturally()
    player.current.src = newTrack
    player.current.autoplay = wasPlaying
    player.current.load()
  }, [index, shouldReload])

  const playNext = useCallback(
    () => setIndex(i => (i + 1) % tracks.current.length),
    []
  )

  const playPrev = useCallback(
    () => setIndex(i => Math.abs(i - 1) % tracks.current.length),
    []
  )

  const contextValue = React.useMemo(
    () => ({
      player,
      load,
      error,
      loading,
      playing,
      volume,
      index,
      playNext,
      playPrev,
      ready: !loading && !error
    }),
    [load, error, loading, playing, volume, index, playNext, playPrev]
  )

  const onEnded = () => {
    if (tracks.current.length > 1) playNext()
  }

  return (
    <AudioPlayerContext.Provider value={contextValue}>
      <audio
        ref={player}
        // controls // uncomment for debug
        onPlay={() => setPlaying(true)}
        onPause={() => !hasEndedNaturally() && setPlaying(false)}
        onCanPlay={() => {
          forceReload.current = false
          setLoading(false)
        }}
        onError={err => setError(err)}
        onEnded={onEnded}
        onLoadStart={() => {
          setLoading(true)
          if (forceReload.current) setPlaying(false)
        }}
        onVolumeChange={() => setVolume(player.current.volume)}
      />
      {children}
    </AudioPlayerContext.Provider>
  )
}

const emptyObject = {}

export const useAudioPlayer = (props = emptyObject) => {
  const { player, load, ...context } = useContext(AudioPlayerContext)

  const { source } = props

  useEffect(() => {
    // if useAudioPlayer is called without source
    // don't do anything: the user will have access
    // to the current context
    if (!source) return
    load(source)
  }, [source, load])

  const setVolume = useCallback(v => (player.current.volume = v), [player])

  return {
    ...context,
    play: player.current ? player.current.play.bind(player.current) : noop,
    pause: player.current ? player.current.pause.bind(player.current) : noop,
    setVolume: player.current ? setVolume : noop
  }
}

// gives current audio position state updates in an animation frame loop for animating visualizations
export const useAudioPosition = () => {
  const { player, ready } = React.useContext(AudioPlayerContext)

  const [position, setPosition] = useState(0)
  const [duration, setDuration] = useState(0)

  // sets position and duration on mount
  useEffect(() => {
    const _player = player.current
    const updateTime = () => setPosition(_player.currentTime)
    if (_player && ready) {
      _player.addEventListener('timeupdate', updateTime)
      updateTime()
      setDuration(_player.duration)
    }
    return () => {
      if (_player) _player.removeEventListener('timeupdate', updateTime)
    }
  }, [player, ready])

  return { position, duration }
}

export const formatTime = time => {
  const hours = ~~(time / 3600)
  const minutes = ~~(time / 60) % 60
  const seconds = ~~time - hours * 3600 - minutes * 60

  return (
    (hours > 0 ? hours + ':' : '') +
    String(minutes).padStart(hours > 0 ? 2 : 1, 0) +
    ':' +
    String(seconds).padStart(2, 0)
  )
}
