import React from 'react'
import styled from '@emotion/styled'

const Flex = styled.div`
  display: flex;
  overflow: hidden;
`

const Animated = styled.div`
  animation: show 750ms cubic-bezier(0.25, 0.46, 0.45, 0.94) backwards;
  @keyframes show {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }
`

const AnimatedLetters = ({ children, text, delay = 0, style, trail = 0 }) => {
  if (!!text)
    return (
      <Flex style={style}>
        {text.split('').map((l, i) => (
          <Animated
            key={i}
            style={{ animationDelay: `${delay + i * trail}ms` }}
            dangerouslySetInnerHTML={{
              __html: l === ' ' ? '&nbsp;' : l
            }}
          />
        ))}
      </Flex>
    )

  return (
    <Flex style={style}>
      <Animated style={{ animationDelay: `${delay}ms` }}>{children}</Animated>
    </Flex>
  )
}

export default AnimatedLetters
