import React from 'react'
import { css } from '@emotion/core'

const elapsedCss = css`
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
  transform-origin: left;
  transition: transform 500ms linear;
`

export default ({ className, position, duration }) => (
  <div className={className}>
    <div
      css={elapsedCss}
      style={{ transform: `scaleX(${duration ? position / duration : 0})` }}
    />
  </div>
)
