import { useCallback, useEffect } from 'react'
import { useSpring } from 'react-spring'

export default function useSmoothScroll() {
  const [, set, stop] = useSpring(() => ({
    s: 0,
    config: { friction: 30, tension: 90, precision: 1 }
  }))

  useEffect(() => {
    const stopScrolling = () => stop()
    window.addEventListener('wheel', stopScrolling)
    window.addEventListener('touchstart', stopScrolling)
    return () => {
      window.removeEventListener('wheel', stopScrolling)
      window.removeEventListener('touchstart', stopScrolling)
    }
  }, [stop])

  const scrollTo = useCallback(
    (s = 0) => {
      set({
        from: { s: window.scrollY },
        to: { s: Math.floor(s) },
        reset: true,
        onChange: ({ s }) => window.scrollTo(0, s)
      })
    },
    [set]
  )

  return scrollTo
}
