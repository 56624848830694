import React, { useState } from 'react'
import { css } from '@emotion/core'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useIntl } from 'react-intl'
import media from '../styles/mq'

import arrowSVG from '../icons/arrow.svg'

const newsletterCss = css`
  display: flex;
  flex-direction: column;
  padding: var(--space-medium) 0;
  font-size: var(--font-size-small);
  ${media.greaterThan('medium')} {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`

const textCss = css`
  max-width: 400px;
  margin-bottom: var(--space-medium);
  ${media.greaterThan('medium')} {
    flex-basis: 40%;
    margin-bottom: 0;
  }
  h5 {
    margin-top: 0;
    font-size: var(--font-size-small);
  }
  p {
    font-size: var(--font-size-small);
    margin: 0;
  }
`

const resultCss = css`
  flex-basis: 40%;
  max-width: 400px;
  text-align: right;
`

const formCss = css`
  max-width: 400px;
  display: flex;
  position: relative;
  align-items: center;
  ${media.greaterThan('medium')} {
    flex-basis: 40%;
  }

  input[type='email'] {
    padding-right: 2.8em;
    width: 100%;
    color: var(--color-black);
    border: 1px solid var(--color-grey);
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type='submit'] {
    position: absolute;
    color: transparent;
    background: url(${arrowSVG}) right no-repeat;
    border: none;
    right: 1.4em;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
`

const NewsletterForm = () => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const { formatMessage: t } = useIntl()

  const handleSubmit = async e => {
    e.preventDefault()
    const { result } = await addToMailchimp(email)
    if (result === 'success') setSuccess(true)
  }

  return (
    <div css={newsletterCss}>
      <div css={textCss}>
        <h5>{t({ id: 'subscribe to newsletter' })}</h5>
        <p>{t({ id: 'subscribe description' })}</p>
      </div>
      {success ? (
        <div css={resultCss}>{t({ id: 'newsletter success' })}</div>
      ) : (
        <form css={formCss} onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            aria-label={t({ id: 'email placeholder' })}
            placeholder={t({ id: 'email placeholder' })}
          />
          <input type="submit" value="OK" />
        </form>
      )}
    </div>
  )
}

export default NewsletterForm
