import { css } from '@emotion/core'
import media from '../styles/mq'

export default css`
  :root {
    /* COLORS */
    --color-black: #000;
    --color-darkest: #262424;
    --color-darker: #7f7f7f;
    --color-dark: #6e6355;
    --color-grey: #e7e2dd;
    --color-brown: #6c6357;
    --color-light: #fbf5ef;
    --color-lighter: #faf5f0;
    --color-lightest: #fffcf8;
    --color-white: #fff;

    /* MARGINS AND SPACES */
    --space-horizontal: 1rem;
    --space-smallest: 0.5rem;
    --space-smaller: 1.2rem;
    --space-small: 1.5rem;
    --space-medium: 2.4rem;
    --space-large: 3rem;
    --space-larger: 5.5rem;
    --space-largest: 8rem;
    --margin-mobile: 25px;

    ${media.lessThan('large')} {
      --space-largest: 5rem;
      --space-larger: 3.5rem;
      --space-smaller: 0.6rem;
    }

    ${media.lessThan('medium')} {
      --space-medium: 2rem;
    }

    ${media.lessThan('small')} {
      --space-largest: 4.5rem;
      --space-larger: 2.5rem;
      --space-large: 2rem;
    }

    /* WIDTH */
    --width-xlarge: 80vw;
    --width-large: 80vw;
    --width-medium: 68vw;
    --width-small: 50vw;
    --max-width-xlarge: 1360px;
    --max-width-large: 1224px;
    --max-width-medium: 1080px;
    --max-width-small: 612px;

    ${media.lessThan('medium')} {
      --width-xlarge: auto;
      --width-large: auto;
      --width-medium: auto;
      --width-small: auto;
      --max-width-xlarge: none;
      --max-width-large: none;
      --max-width-medium: none;
      --max-width-small: none;
    }

    ${media.only('large')} {
      --width-large: 90vw;
      --width-xlarge: 90vw;
      --width-small: 60vw;
    }

    /* FONTS */
    --font-size-medium: 1.4rem;
    --font-size-big: 2rem;
    --font-size-bigger: 3.5rem;
    --font-size-even-bigger: 5.3rem;
    --font-size-biggest: 9.7rem;
    --font-size-small: 0.95rem;
    --font-size-smaller: 0.82rem;
    --font-size-smallest: 0.82rem;
    --font-family-sans: 'Work sans', sans-serif;
    --font-family-serif: 'Playfair Display';
    --font-size-mobile-menu: 2.5rem;

    ${media.lessThan('large')} {
      --font-size-biggest: 7rem;
    }
    ${media.lessThan('medium')} {
      --font-size-biggest: 6rem;
      --font-size-even-bigger: 4rem;
      --font-size-smaller: 0.95rem;
      --font-size-smallest: 0.9rem;
    }
    ${media.lessThan('small')} {
      --font-size-biggest: 3rem;
      --font-size-even-bigger: 2.5rem;
      --font-size-bigger: 2.6rem;
      --font-size-big: 1.8rem;
    }

    --letter-spacing-uppercase: -0.03em;

    /* VARIABLES */
    --player-height: 3.25rem;
    --notification-height: 2.25rem;
    --m-notification-height: calc(var(--notification-height) * -1);
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  body {
    font: 120%/1.45em var(--font-family-sans);
    letter-spacing: -0.06em;
    line-height: 1.7;
    color: var(--color-darkest);
    background-color: var(--color-lightest);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    margin: 0;
  }

  main {
    overflow-x: hidden;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;
    white-space: nowrap;
    table-layout: fixed;
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  h1,
  h2,
  h3 {
    font-family: var(--font-family-serif);
    font-weight: normal;
    line-height: 1.2;
  }

  h1 {
    font-size: var(--font-size-biggest);
    margin-top: var(--space-small);
    margin-bottom: var(--space-larger);
  }

  h2 {
    font-size: var(--font-size-bigger);
    margin-top: var(--space-largest);
    margin-bottom: var(--space-larger);
  }

  h3 {
    font-size: var(--font-size-big);
    margin-top: var(--space-large);
    margin-bottom: var(--space-large);
  }

  h4,
  h5,
  h6 {
    font-size: var(--font-size-smaller);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-uppercase);
  }

  h4 {
    padding-bottom: var(--space-smaller);
    margin-bottom: var(--space-small);
    border-bottom: 1px solid var(--color-grey);
  }

  h2.bar {
    position: relative;
    padding-left: calc(var(--space-larger) + var(--space-smaller));
    :before {
      content: '';
      position: absolute;
      left: 0;
      height: 2px;
      top: calc(50% + 0.08em);
      width: var(--space-larger);
      background-color: currentColor;
    }
  }

  p {
    margin-bottom: var(--space-small);
  }

  p + :not(p) {
    margin-top: var(--space-large);
  }

  picture > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .block p a {
    position: relative;
    margin-right: 0.3rem;
    font-weight: 600;
    transition: color 250ms ease;
    :hover {
      color: var(--color-darker);
    }
    :after {
      content: '→';
      position: relative;
      top: 0.1rem;
      left: 0.2rem;
      color: var(--color-grey);
    }
    :before {
      content: '';
      position: absolute;
      left: -1px;
      right: -1px;
      bottom: -4px;
      height: 4px;
      background-color: var(--color-grey);
    }
  }

  q {
    font-family: 'Playfair Display', serif;
    line-height: 1.4;
    :before {
      content: '\\201C';
    }
    :after {
      content: '\\201D';
    }
  }

  q.block {
    font-size: var(--font-size-big);
    border-left: 6px solid currentColor;
    padding-left: var(--space-large);
  }

  a {
    text-decoration: none;
    color: inherit;
    transition: opacity 250ms ease-out;
  }

  button,
  textarea,
  select {
    font: inherit;
  }

  input {
    font-size: 0.85em;
    color: var(--color-dark);
    padding: 1.4em;
  }

  input:focus {
    outline: none;
  }

  .separator {
    width: 100%;
    height: 2px;
    background-color: var(--color-darkest);
    margin-bottom: var(--space-large);
  }

  .block {
    display: block;
    position: relative;
    margin-top: var(--space-large);
    margin-bottom: var(--space-largest);
    margin-left: auto;
    margin-right: auto;
    ${media.greaterThan('medium')} {
      :last-child {
        margin-bottom: var(--space-large);
      }
    }
  }

  .c-xlarge,
  .c-large,
  .c-medium,
  .c-small {
    margin-left: var(--margin-mobile);
    margin-right: var(--margin-mobile);
    ${media.greaterThan('medium')} {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .c-xlarge {
    width: var(--width-xlarge);
    max-width: var(--max-width-xlarge);
    ${media.lessThan('medium')} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .c-large {
    width: var(--width-large);
    max-width: var(--max-width-large);
  }

  .c-medium {
    width: var(--width-medium);
    max-width: var(--max-width-medium);
  }

  .c-small {
    width: var(--width-small);
    max-width: var(--max-width-small);
  }

  .fixedImg {
    display: block !important;
    width: 100% !important;
    height: 100%;
    max-width: none !important;
    > div {
      padding-top: 0 !important;
      height: 100% !important;
      max-width: none !important;
    }
    > picture {
      height: 100%;
    }
  }

  .m-hide {
    ${media.lessThan('medium')} {
      display: none !important;
    }
  }

  .m-only {
    ${media.greaterThan('medium')} {
      display: none !important;
    }
  }

  .l-hide {
    ${media.lessThan('large')} {
      display: none !important;
    }
  }

  .l-only {
    ${media.greaterThan('large')} {
      display: none !important;
    }
  }

  button {
    cursor: pointer;
    :focus {
      outline: none;
    }
  }
`
