export const defaultBreakpoints = {
  small: 450,
  medium: 768,
  large: 1170,
  huge: 1440
}

function getSizeFromBreakpoint(breakpointValue, breakpoints = {}) {
  if (breakpoints[breakpointValue]) {
    return breakpoints[breakpointValue]
  } else if (parseInt(breakpointValue)) {
    return breakpointValue
  } else {
    console.error(
      `styled-media-query: No valid breakpoint or size specified for ${breakpointValue}.`
    )
    return '0'
  }
}

function getPreviousBreakpoint(breakpointValue, breakpoints = {}) {
  const keys = Object.keys(breakpoints)
  const nextBreakpointValue = keys.indexOf(breakpointValue) - 1
  return keys[nextBreakpointValue]
}

export function generateMedia(breakpoints = defaultBreakpoints) {
  const lessThan = breakpoint =>
    `@media (max-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}px)`

  const greaterThan = breakpoint =>
    `@media (min-width: ${getSizeFromBreakpoint(breakpoint, breakpoints) +
      1}px)`

  const between = (firstBreakpoint, secondBreakpoint) =>
    `@media (min-width: ${getSizeFromBreakpoint(firstBreakpoint, breakpoints) +
      1}px) and (max-width: ${getSizeFromBreakpoint(
      secondBreakpoint,
      breakpoints
    )}px)`

  const only = breakpoint =>
    between(getPreviousBreakpoint(breakpoint, breakpoints), breakpoint)

  return Object.assign({
    lessThan,
    greaterThan,
    between,
    only
  })
}

const media = generateMedia()

export default media

export const mediaHover = '@media (hover: hover)'
