import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { renderMetaTags } from 'react-datocms'
import { useLocale, usePageContext } from '../hooks/usePageContext'

function SEO({ seoData }) {
  const locale = useLocale()
  const { hrefLang } = usePageContext()
  const { dato, site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
      dato {
        _site {
          faviconMetaTags {
            ...TagRecordFragment
          }
        }
      }
    }
  `)

  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      {renderMetaTags(seoData.concat(dato._site.faviconMetaTags))}
      {Object.entries(hrefLang).map(([locale, href]) => (
        <link
          rel="alternate"
          href={site.siteMetadata.siteUrl + href}
          hrefLang={locale}
          key={locale}
        />
      ))}
    </Helmet>
  )
}

export default SEO
