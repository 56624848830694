import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { useIntl } from 'react-intl'
import media from '../styles/mq'

import useSmoothScroll from '../hooks/useSmoothScroll'
import NewsletterForm from '../components/NewsetterForm'
import { usePageContext } from '../hooks/usePageContext'

const footerCss = css`
  color: var(--color-white);
  background-color: var(--color-darkest);
  font-weight: bold;
  font-size: var(--font-size-small);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-uppercase);
`

const footerWrapperCss = css`
  display: flex;
  padding: var(--space-medium) 0;
  ${media.between('medium', 'large')} {
    padding-bottom: calc(var(--space-medium) + var(--player-height));
  }
  ${media.lessThan('medium')} {
    flex-direction: column;
    align-items: center;
    > div {
      margin: var(--space-smaller) 0;
    }
  }
  > .social {
    > a {
      padding: 0 var(--space-smaller);
    }
  }
  > .locales {
    > a {
      padding: var(--space-smaller);
    }
    ${media.greaterThan('medium')} {
      padding-left: var(--space-smaller);
      > a {
        padding: 0;
      }
      > a:first-of-type {
        padding-right: var(--space-smaller);
      }
    }
    .active {
      opacity: 0.3;
    }
  }
`

const BackToTop = styled.div`
  font-weight: bold;
  font-size: var(--font-size-small);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-uppercase);
  text-align: center;
  margin-top: var(--space-medium);
`

const backToTopCss = css`
  cursor: pointer;
  margin-bottom: var(--space-medium);
`

const Footer = ({ backToTop }) => {
  const scrollTop = useSmoothScroll()
  const { hrefLang, site, settingsSite } = usePageContext()

  const { formatMessage: t } = useIntl()

  return (
    <>
      {backToTop && (
        <BackToTop className="c-large" role="navigation">
          <div css={backToTopCss} onClick={() => scrollTop()}>
            {t({ id: 'back to top' })}
          </div>
          <div className="separator" style={{ margin: 0 }} />
        </BackToTop>
      )}
      <div className="c-large" role="complementary">
        <NewsletterForm />
      </div>
      <footer css={footerCss}>
        <div className="c-large">
          <div css={footerWrapperCss}>
            <div style={{ flex: 1 }}>
              Gabriel Bismut — ©‘20 All rights reserved
            </div>
            <div className="social">
              <a
                href={`mailto:${settingsSite.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Email
              </a>
              <a
                href={site.globalSeo.facebookPageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                href={settingsSite.youtubePageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Youtube
              </a>
              <a
                href={settingsSite.spotifyPageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Spotify
              </a>
            </div>
            {hrefLang && (
              <div className="locales">
                {Object.entries(hrefLang).map(([locale, link]) => (
                  <Link key={locale} to={link} activeClassName="active">
                    {locale}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
