import React, { useEffect, useRef } from 'react'
import { css } from '@emotion/core'
import { animated, useSpring } from 'react-spring'

import CloseIcon from '../icons/close.inline.svg'
import SVGButton from '../components/SVGButton'

const notifWrapperCss = css`
  display: flex;
  align-items: stretch;
  overflow: hidden;
  justify-content: flex-end;
  flex-direction: column;
`

const notifCss = css`
  display: flex;
  flex-shrink: 0;
  position: relative;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light);
  color: var(--color-darkest);
  animation: appear 350ms ease 3.8s forwards;
  padding: var(--space-smallest) var(--space-small);

  @keyframes appear {
    from {
      background-color: var(--color-light);
      color: var(--color-darkest);
    }
    to {
      background-color: var(--color-darkest);
      color: var(--color-white);
    }
  }
`

const innerCss = css`
  font-size: var(--font-size-smallest);
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: var(--letter-spacing-uppercase);
  p {
    margin: 0;
  }
  a {
    text-decoration: underline;
  }
`

const closeCss = css`
  position: absolute;
  right: var(--space-small);
`

const Notification = ({ content }) => {
  const ref = useRef(null)
  const hidden = useRef(false)
  const [style, set] = useSpring(() => ({ height: 0 }))

  const hide = () => {
    hidden.current = true
    set({ height: 0 })
  }

  useEffect(() => {
    const updateHeight = event => {
      if (hidden.current) return
      const { height } = ref.current.getBoundingClientRect()
      set({ height, delay: !!event ? 0 : 1000, immediate: !!event })
    }

    window.addEventListener('resize', updateHeight)
    updateHeight()
    return () => window.removeEventListener('resize', updateHeight)
  }, [set])

  return (
    <animated.div css={notifWrapperCss} style={style} role="alert">
      <div ref={ref} css={notifCss}>
        <div
          className="c-large"
          css={innerCss}
          dangerouslySetInnerHTML={{ __html: content.text }}
        />
        <SVGButton
          css={closeCss}
          Icon={CloseIcon}
          align="flex-end"
          onClick={hide}
        />
      </div>
    </animated.div>
  )
}

export default Notification
