import React, { useRef, useState, useCallback, useEffect } from 'react'
import { css} from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import { usePageContext } from '../hooks/usePageContext'
import Logo from './Logo'
import closeIcon from '../icons/close.inline.svg'
import burgerIcon from '../icons/burger.inline.svg'
import SVGButton from '../components/SVGButton'
import facebookIcon from '../icons/facebook.inline.svg'
import youtubeIcon from '../icons/youtube.inline.svg'
import spotifyIcon from '../icons/spotify.inline.svg'
import AnimatedLetters from '../components/AnimatedLetters'

const Header = styled.header`
  position: relative;
  z-index: 100;
  color: var(--color-darkest);
  &.open {
    height: 100vh;
    color: var(--color-white);
    background-color: var(--color-darkest);
  }
`

const Nav = styled.nav`
  padding: var(--space-small) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Menu = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-mobile-menu);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.45em;
  padding: 0 var(--margin-mobile) var(--space-medium) var(--margin-mobile);
  background-color: var(--color-darkest);
  > * {
    flex-shrink: 0;
  }
  a.active {
    display: flex;
    align-items: center;
    opacity: 0.2;
    :before {
      content: '•';
      font-size: 0.7em;
      margin-right: var(--space-smallest);
    }
  }
`

const Social = styled.div`
  display: flex;
  margin-top: var(--space-medium);
  opacity: 0.5;
`

const logoCss = css`
  display: inline-flex;
  align-items: center;
  text-align: center;
  transition: transform 600ms ease-out;
`

const HeaderMobile = ({ path }) => {
  const ref = useRef(null)
  const menuRef = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuHeight, setMenuHeight] = useState(0)
  const toggleMenu = useCallback(() => {
    if (!menuOpen) {
      const { bottom } = ref.current.getBoundingClientRect()
      const height = window.innerHeight - bottom
      setMenuHeight(height)
    }
    setMenuOpen(m => !m)
  }, [menuOpen])

  const { allMenuEntries, site, settingsSite } = usePageContext()

  useEffect(() => void setMenuOpen(false), [path])

  useEffect(() => {
    menuOpen ? disableBodyScroll(menuRef.current) : clearAllBodyScrollLocks()
  }, [menuOpen])

  return (
    <Header ref={ref} className={`m-only ${menuOpen ? 'open' : ''}`}>
      <Nav className="c-large">
        <Link to={allMenuEntries[0].link.slug} css={logoCss}>
          <Logo id="mobile" />
        </Link>
        <SVGButton
          Icon={menuOpen ? closeIcon : burgerIcon}
          onClick={toggleMenu}
          iconSize={30}
          align="flex-end"
        />
      </Nav>
      {menuOpen && (
        <Menu ref={menuRef} style={{ height: menuHeight }}>
          {allMenuEntries.map(({ link: { title, slug } }, i) => (
            <AnimatedLetters key={slug} delay={i * 95}>
              <Link to={slug} activeClassName="active">
                {title}
              </Link>
            </AnimatedLetters>
          ))}
          <AnimatedLetters delay={allMenuEntries.length * 75 + 800}>
            <Social>
              <a
                href={site.globalSeo.facebookPageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SVGButton
                  Icon={facebookIcon}
                  iconSize="2.5rem"
                  style={{ marginLeft: '-0.8rem' }}
                />
              </a>
              <a
                href={settingsSite.youtubePageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SVGButton Icon={youtubeIcon} iconSize="2.5rem" />
              </a>
              <a
                href={settingsSite.spotifyPageUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{marginLeft: '0.3rem'}}
              >
                <SVGButton Icon={spotifyIcon} iconSize="2.5rem" />
              </a>
            </Social>
          </AnimatedLetters>
        </Menu>
      )}
    </Header>
  )
}

export default HeaderMobile
