import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import {
  useAudioPlayer,
  useAudioPosition,
  formatTime
} from '../../hooks/useAudioPlayer'

import SVGButton from '../../components/SVGButton'
import Digits from './Digits'
import Scrubber from './Scrubber'
import prevSVG from '../../icons/prevnext.inline.svg'
import playSVG from '../../icons/play.inline.svg'
import pauseSVG from '../../icons/pause.inline.svg'
import volumeonSVG from '../../icons/volumeon.inline.svg'
import volumeoffSVG from '../../icons/volumeoff.inline.svg'
import media from '../../styles/mq'

const SOUNDCLOUD_HOST = 'https://api.soundcloud.com/tracks'
const SOUNDCLOUD_CLIENT_ID = '4cff67350555344e335f265f39d6d893'

const Wrapper = styled.aside`
  display: flex;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
  z-index: 1000;
  align-items: flex-end;
  ${media.greaterThan('large')} {
    align-items: center;
    justify-content: flex-end;
  }
`

const Player = styled.div`
  pointer-events: all;
  font-size: var(--font-size-smaller);
  font-weight: 500;
  background-color: var(--color-black);
  color: var(--color-white);
  width: 100%;
  padding: 0 1.5rem;
  ${media.greaterThan('large')} {
    width: 100vh;
    transform-origin: center right;
    transform: rotate(-90deg) translate(50%, -50%);
    animation: slide 350ms ease-out 2s backwards;
  }

  @keyframes slide {
    from {
      transform: rotate(-90deg) translate(50%, 50%);
    }
    to {
      transform: rotate(-90deg) translate(50%, -50%);
    }
  }
`

const Row = styled.div`
  height: var(--player-height);
  display: flex;
  flex-wrap: nowrap;
  padding: 0.4rem 0;
  align-items: center;
`

const Controls = styled.div`
  margin: 0 0.8em;
  display: flex;
`

const controlCss = css`
  margin: 0 0.3rem;
  transition: opacity 250ms ease-out;
  :hover {
    opacity: 0.6;
  }
`

const scrubberCss = css`
  flex: 1;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 0.8em;
`

const bandCss = css`
  margin: 0 1.5em;
  opacity: 0.3;
  flex: 0.6;
  min-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Timer = styled.div`
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  margin: 0 0.8em;
`

const timeSlashCss = css`
  margin: 0 0.8em;
  opacity: 0.3;
`

const durationCss = css`
  opacity: 0.3;
`

const AudioPlayer = React.memo(() => {
  const { dato } = useStaticQuery(graphql`
    {
      dato {
        allAudioTracks(orderBy: position_ASC) {
          sountrackId
          title
          bandAlt
          band {
            name
          }
        }
      }
    }
  `)

  const streams = dato.allAudioTracks.map(
    t =>
      `${SOUNDCLOUD_HOST}/${t.sountrackId}/stream?client_id=${SOUNDCLOUD_CLIENT_ID}`
  )

  const {
    play,
    pause,
    playing,
    index,
    playNext,
    playPrev,
    volume,
    setVolume
  } = useAudioPlayer({
    source: streams
  })

  const { title, band, bandAlt } = dato.allAudioTracks[index]

  const { position, duration } = useAudioPosition()

  const togglePlay = playing
    ? pause
    : () => {
        play()
        trackCustomEvent({
          category: 'Audio Player',
          action: 'Play',
          label: 'Song played',
          value: title
        })
      }
  const toggleMute = () => (volume ? setVolume(0) : setVolume(1))

  return (
    <Wrapper className="m-hide">
      <Player>
        <Row>
          {title}
          <span css={bandCss}>{band ? band.name : bandAlt}</span>
          <Controls>
            <SVGButton
              css={controlCss}
              Icon={prevSVG}
              size={16}
              iconSize="0.9rem"
              onClick={() => playPrev()}
            />
            <SVGButton
              css={controlCss}
              Icon={playing ? pauseSVG : playSVG}
              size={16}
              iconSize="0.9rem"
              onClick={() => togglePlay()}
            />
            <SVGButton
              css={controlCss}
              Icon={prevSVG}
              size={16}
              iconSize="0.9rem"
              onClick={() => playNext()}
              style={{ transform: 'rotate(180deg)' }}
            />
          </Controls>
          <Scrubber
            css={scrubberCss}
            position={position}
            duration={duration}
          />
          <Timer>
            <span>
              <Digits value={formatTime(position)} />
            </span>
            <span css={timeSlashCss}>/</span>
            <span css={durationCss}>
              <Digits value={formatTime(duration)} />
            </span>
          </Timer>
          <SVGButton
            css={controlCss}
            Icon={volume ? volumeonSVG : volumeoffSVG}
            size={16}
            iconSize="0.9rem"
            onClick={toggleMute}
            style={{ marginRight: 0 }}
          />
        </Row>
      </Player>
    </Wrapper>
  )
})

// AudioPlayer.whyDidYouRender = true

export default AudioPlayer
