import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { usePageContext } from '../hooks/usePageContext'

import Logo from './Logo'
import SVGButton from '../components/SVGButton'
import facebookIcon from '../icons/facebook.inline.svg'
import youtubeIcon from '../icons/youtube.inline.svg'
import spotifyIcon from '../icons/spotify.inline.svg'

const Header = styled.header`
  position: relative;
  z-index: 10;
  width: var(--width-xlarge);
`

const Nav = styled.nav`
  padding: var(--space-smaller) 0 var(--space-small);
  display: flex;
  align-items: center;
  color: var(--color-darkest);
  font-size: var(--font-size-smaller);
  font-weight: 500;
  text-transform: uppercase;
  > a:not([target='_blank']) {
    opacity: 0.6;
    :not(:first-of-type) {
      margin: 0 var(--space-smaller);
    }
    :hover {
      opacity: 1;
    }
    &.active {
      position: relative;
      opacity: 1;
      :after {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: calc(50% - 0.25rem);
        background-color: currentColor;
        height: 0.25rem;
        width: 0.25rem;
        border-radius: 50%;
      }
    }
  }
  &.white > a {
    opacity: 0.8;
    color: var(--color-white);
    :hover {
      opacity: 1;
    }
  }
`

const logoCss = css`
  display: inline-flex;
  align-items: center;
  text-align: center;
  transition: transform 600ms ease-out;
  :hover {
    transform: rotate(360deg) scale(1.2);
  }
`

const HeaderDesktop = () => {
  const { allMenuEntries, site, settingsSite } = usePageContext()

  return (
    <Header className="c-large m-hide">
      <Nav>
        <Link to={allMenuEntries[0].link.slug} css={logoCss}>
          <Logo />
        </Link>
        <div style={{ flex: 1 }} />
        {allMenuEntries.map(({ link: { title, slug } }) => (
          <Link key={slug} to={slug} activeClassName="active">
            {title}
          </Link>
        ))}
        <a
          href={site.globalSeo.facebookPageUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <SVGButton
            Icon={facebookIcon}
            size={30}
            iconSize="1.5rem"
            style={{ marginLeft: 'var(--space-small)' }}
          />
        </a>
        <a
          href={settingsSite.youtubePageUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Youtube"
        >
          <SVGButton Icon={youtubeIcon} size={30} iconSize="1.5rem" />
        </a>
        <a
          href={settingsSite.spotifyPageUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Spotify"
          style={{marginLeft: '0.3rem'}}
        >
          <SVGButton Icon={spotifyIcon} size={30} iconSize="1.5rem" />
        </a>
      </Nav>
    </Header>
  )
}

export default HeaderDesktop
